<template>
  <AuctionListItemInfoElement v-if="shouldBeDisplayed" icon="bxs:file-pdf">
    <CommonLink
      tabindex="0"
      role="button"
      underline
      class="cursor-pointer text-sm capitalize"
      @click.stop="onCatalogClick"
      @keyup.enter.stop="onCatalogClick"
    >
      {{ $t('auction-list.catalog') }}
    </CommonLink>
  </AuctionListItemInfoElement>

  <DialogCatalog ref="dialogCatalogRef" />
</template>
<script setup lang="ts">
import CommonLink from '@autobid/ui/components/common/Link.vue'
import type { DisplayingAuction } from '@autobid/ui/types/components/AuctionsData'
import DialogCatalog from '@autobid/ui/components/common/dialog/Catalog.vue'
import { useAuctionCatalog } from '@autobid/ui/composables/useAuctionCatalog'
import { computed, inject } from 'vue'
import { APPS_THAT_NEED_AUTH } from '@autobid/ui/constants/APPS_THAT_NEED_AUTH'
import { APP_ID_NAME_MAP } from '@autobid/ui/constants/APP_ID_NAME_MAP'
import { useAutobidAuth } from '@autobid/nuxt-auth/src/composables/useAutobidAuth'
import AuctionListItemInfoElement from './AuctionListItemInfoElement.vue'

const auction = inject<DisplayingAuction>('auction')
const { dialogCatalogRef, onCatalogClick } = useAuctionCatalog(auction)
const { isAuthed } = useAutobidAuth()
const shouldBeDisplayed = computed(() => {
  if (!auction?.catalog) return false

  const doesNeedAuth = APPS_THAT_NEED_AUTH.includes(
    APP_ID_NAME_MAP[auction.appId]
  )

  if (!doesNeedAuth) return true

  return !!isAuthed.value
})
</script>
